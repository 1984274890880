import React from "react";
import { BsShieldFillCheck } from "react-icons/bs";
import { BiSearchAlt } from "react-icons/bi";
import { RiHeart2Fill } from "react-icons/ri";
import { AiOutlineTeam } from "react-icons/ai";

import ReactPlayer from 'react-player'
import animationVideo from "../assets/animation_video.webm";

// Reuseable component for each card in the section
const ServiceCard = ({ color, title, icon, subtitle, delay }) => (
  <div style={{width:"100%"}} className="flex flex-row justify-start items-start white-glassmorphism p-3 m-2 cursor-pointer hover:shadow-xl" data-aos="fade-up-left" data-aos-delay={delay}>
    <div className={`w-10 h-10 rounded-full flex justify-center items-center ${color}`} style={{margin:"auto"}}>
      {icon}
    </div>
    <div className="ml-5 flex flex-col flex-1">
      <h3 className="mt-2 text-white text-lg">{title}</h3>
      <p style={{paddingBottom:"15px"}} className="mt-1 text-white text-sm md:w-9/12">
        {subtitle}
      </p>
    </div>
  </div>
);

// Flex based responsive layout, row for larger devices and col for smaller
const Services = () => (
  <div className="flex w-full justify-center items-center gradient-bg-testimonials">
    <div className="flex mf:flex-row flex-col items-center justify-between md:p-20 py-12 px-4">
      <div className="flex-1 flex flex-col justify-start items-start">
        <h1 className="text-white text-3xl sm:text-5xl py-2 text-gradient font-bold" data-aos="fade-up" data-aos-delay="100">
          Features that we
          <br />
          continue to improve
        </h1>
        <p className="text-left my-2 text-white font-light md:w-9/12 w-11/12 text-base" data-aos="fade-up" data-aos-delay="200">
          The best choice for buying and selling your crypto assets, with
          various super friendly services we offer
        </p>
        
        <ReactPlayer 
            width="95%"
            height="400px"
            style={{minHeight: '200px', maxHeight: '800px', height: 'auto!important'}}
            url={animationVideo}
            // light={previewImage}
            playing={true}
            loop={true}
            volume={0}
            controls={false}
        />
      </div>

      <div className="flex-1 flex flex-col justify-start items-center">
        <ServiceCard
          color="bg-[#2952E3]"
          title="Security guarantee"
          icon={<BsShieldFillCheck fontSize={21} className="text-white"/>}
          subtitle="Security is guaranteed. We always maintain privacy and maintain the quality of our products"
          delay="400"
        />
        <ServiceCard
          color="bg-[#8945F8]"
          title="Best exchange rates for various assets"
          icon={<BiSearchAlt fontSize={21} className="text-white" />}
          subtitle="We use only up to date prices provided by Stellar Network. No more weird, hidden conversions & fees"
          delay="500"
        />
        <ServiceCard
          color="bg-[#F84550]"
          title="Fastest transactions"
          icon={<RiHeart2Fill fontSize={21} className="text-white" />}
          subtitle="Make your crypto work for you! Our platform will show you how to earn crypto securely - easily"
          delay="600"
        />
        <ServiceCard
          color="bg-[#088F8F]"
          title="Active developement team"
          icon={<AiOutlineTeam fontSize={21} className="text-white" />}
          subtitle="We are a team of professionals who work constantly to bring you a product as successful as possible"
          delay="700"
        />
      </div>
    </div>
  </div>
);

export default Services;
