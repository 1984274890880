import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import animationLaunch from '../assets/animation_launch.webm';

class SaleCountdown extends Component {
    constructor(props) {
        super(props)

        this.state = {
            Day: "00",
            Hours: "00",
            Minute: "00",
            Second: "00"
        }
    }
    componentDidMount() {
        // let tempDate = new Date();
        // let tempYear = tempDate.getFullYear();
        // let futureDate = new Date(2022, 8, 21, 0, 0, 0);
        var futureTime = new Date("2022-09-21T07:00:00.000-07:00").getTime();

        // const futureTime = futureDate.getTime();
        
        let countdown = setInterval(() => {
            const today = new Date().getTime();

            const t = futureTime - today;
            const oneDay = 24 * 60 * 60 * 1000;
            const oneHour = 60 * 60 * 1000;
            const oneMinute = 60 * 1000;

            // calculate all values
            let days = t / oneDay;
            days = Math.floor(days);
            let hours = Math.floor((t % oneDay) / oneHour);
            let minutes = Math.floor((t % oneHour) / oneMinute);
            let seconds = Math.floor((t % oneMinute) / 1000);

            function format(item) {
                if (item < 10) {
                    return (item = `0${item}`);
                }
                return item;
            }
            this.setState({
                Day: format(days),
                Hours: format(hours),
                Minute: format(minutes),
                Second: format(seconds)
            })
            if (t < 0) {
                clearInterval(countdown);
            }
        }, 1000);
        
    }
    render() {
        const { Day, Hours, Minute, Second } = this.state;
        // let futureD = new Date(2022, 8, 21, 0, 0, 0);
        let futureD = new Date("2022-09-21T07:00:00.000-07:00");
        const futureT = futureD.getTime();

        return (
            <>
                <section className="countdown-section content gradient-bg-services">
                    {futureT > new Date().getTime() ? 
                    <React.Fragment>
                    <h1 className="text-3xl sm:text-6xl text-white font-bold text-gradient py-3">Launch countdown</h1>
                    <div className="countdown">
                        <div className="label" data-aos="fade-up">
                            <div className="countdown-format">
                                <h4 className="text-3xl sm:text-6xl text-white font-bold ">{Day}</h4>
                            </div>
                            <p>DAYS</p>
                        </div>
                        <span>:</span>
                        <div className="label" data-aos="fade-up">
                            <div className="countdown-format">
                                <h4 className="text-3xl sm:text-6xl text-white font-bold">{Hours}</h4>
                            </div>
                            <p>HOURS</p>
                        </div>
                        <span>:</span>
                        <div className="label" data-aos="fade-up">
                            <div className="countdown-format">
                                <h4 className="text-3xl sm:text-6xl text-white font-bold">{Minute}</h4>
                            </div>
                            <p>MINUTES</p>
                        </div>
                        <span>:</span>
                        <div className="label" data-aos="fade-up">
                            <div className="countdown-format">
                                <h4 className="text-3xl sm:text-6xl text-white font-bold">{Second}</h4>
                            </div>
                            <p>SECONDS</p>
                        </div>
                    </div>
                    </React.Fragment>
                    : 
                    <React.Fragment>
                        <h1 className="text-3xl sm:text-6xl text-white font-bold text-gradient py-3" style={{paddingBottom:"35px"}}>
                            <a href="https://www.lumendex.online" target="_blank">
                                Go to Exchange!
                            </a>
                        </h1>
                    </React.Fragment>
                    }

                    <ReactPlayer 
                        data-aos-delay={500}
                        data-aos="fade-left"
                        width="93%"
                        height="400px"
                        style={{minHeight: '200px', maxHeight: '800px', height: 'auto!important',margin:"auto", marginBottom:"35px"}}
                        url={animationLaunch}
                        // light={previewImage}
                        playing={true}
                        loop={true}
                        volume={0}
                        controls={false}
                    />

                    <h1 style={{margin:"0", paddingBottom:"70px", paddingTop:"55px"}} className="text-3xl sm:text-6xl text-white font-bold text-gradient py-3">
                        <a href="https://t.me/lumendexchannel" target="_blank">
                            Join our telegram channel for up-to-date news
                        </a>
                    </h1>
                    {/* <button className="try-for-free" data-aos="fade-up">PRE ORDER NOW</button> */}
                </section>
            </>
        )
    }
}

export default SaleCountdown
