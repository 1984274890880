import React from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';


const Footer = () => (
  <div style={{height:"400px"}} className="w-full flex md:justify-center justify-between items-center flex-col p-4 gradient-bg-footer">
    <div className="w-full flex sm:flex-row flex-col justify-between items-center my-4">
      <div className="flex flex-[0.5] justify-center items-center">
        <div className="logo-container">
          <h2 className="logo-part1">Lumen</h2>
          <h2 className="logo-part2">Dex</h2>
        </div>
      </div>
      <div className="flex flex-1 justify-evenly items-center flex-wrap sm:mt-0 mt-5 w-full">
        <p className="text-white text-base text-center mx-2 cursor-pointer hover:opacity-75 transition-opacity">Countdown</p>
        <p className="text-white text-base text-center mx-2 cursor-pointer hover:opacity-75 transition-opacity">Features</p>
        <p className="text-white text-base text-center mx-2 cursor-pointer hover:opacity-75 transition-opacity">News</p>
        <a href="https://www.lumendex.online" target="_blank">
        <p style={{color:"#FFFFFF"}} className="bg-[#2952e3] py-2 px-7 mx-4 rounded-full cursor-pointer hover:bg-[#2546bd] transition-all">
          Exchange
        </p>
        </a>
      </div>
    </div>

    {/* Info section */}
    <div className="flex justify-center items-center flex-col mt-5">
      <p className="text-white text-sm text-center">Join us today to do crypto - the right way</p>
      <div className="footer-socials">
        <p className="text-white text-sm text-center font-medium mt-2">
          <a href="mailto:info@lumendex.online" target="_blank">
            <FontAwesomeIcon icon={faEnvelope} className="custom-footer-icon"/>
            info@lumendex.online
          </a>
        </p>
        <p className="text-white text-sm text-center font-medium mt-2">
          <a href="https://twitter.com/lumendexio" target="_blank">
            <FontAwesomeIcon icon={faTwitter} className="custom-footer-icon"/>
            @lumendexio
          </a>
        </p>
        <p className="text-white text-sm text-center font-medium mt-2">
          <a href="https://t.me/lumendexchannel" target="_blank">
            <FontAwesomeIcon icon={faTelegram} className="custom-footer-icon"/>
            @lumendexchannel
          </a>
        </p>
      </div>
    </div>

    <div className="sm:w-[90%] w-full h-[0.25px] bg-gray-400 mt-5 " />

    {/* Extreme bottom section */}
    <div className="sm:w-[90%] w-full flex justify-between items-center mt-3">
      <p className="text-white text-left text-xs">@LumenDex 2022</p>
      <p className="text-white text-right text-xs">All rights reserved</p>
    </div>
  </div>
);

export default Footer;
