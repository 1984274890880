import React from "react";

import LandingStyle from "../roadmap.module.css";


export default function RoadMap() {
    console.log(LandingStyle)
    return (
        <div className="gradient-bg-roadmap" style={{margin:"auto"}}>
            <div className="row" style={{display:"flex", justifyContent:"center",paddingBottom:"50px"}}>
                <div style={{maxWidth:"1300px"}}>
                    <div className="text-center">
                        <h1 className="text-3xl sm:text-6xl text-white font-bold text-gradient py-3" style={{paddingTop:"20px",paddingBottom:"50px"}}>The Roadmap</h1>
                    </div>
                    <div className={LandingStyle.roadmap_boxes}>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2021 Q4</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.circle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Concept generation & research</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2022 Q1 & Q2</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.circle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Team assemble and proving the concept of work</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2022 Q2 & Q3</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.circle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Platform Research on crypto Payments & Strategic Plan </h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2022 Q3</p>
                            <div className={LandingStyle.circle}></div>
                            <h6>Platform design & Crypto trading platform development MVP</h6>
                        </div>

                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2022 Q4</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.circle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Token Launch</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2022 Q4</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.circle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Planning of Token Launch</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2022 Q4</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.circle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Building of Main version from MVP</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2022 Q3 & Q4</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.circle}></div>
                                <div className={LandingStyle.vdotted}></div>
                            </div>
                            <h6>MVP completion & Alpha test </h6>
                        </div>

                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2022 Q4</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.circle}></div>
                                <div className={LandingStyle.vldotted}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Emissions rewards for legitimate token holders</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2022 Q4</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.circle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Increasing rewards for existing members and decreasing lowest entry level</h6>
                        </div>

                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2023 Q1</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.circle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Limited direct buy sale for those who did not make it in time</h6>
                        </div>

                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2023 Q1</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.circle}></div>
                                {/* <div className={LandingStyle.dotted}></div> */}
                            </div>
                            <h6>UI/UX improvements along with bug fixes and introduction of new features</h6>
                        </div>

                        

                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2023 Q2 & Q3</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Collaboration with other big projects</h6>
                        </div>
                        

                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2023 Q2</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Development of desktop application prototype</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2023 Q1 & Q2</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Decentralized referral system with attractive rewards for participants</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2023 Q1 & Q2</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                                <div className={LandingStyle.vdotted}></div>
                            </div>
                            <h6>Building a strong marketing team</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2023 Q3</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                                <div className={LandingStyle.vdotted}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Liquidity farming for legitimate members</h6>
                        </div>
                        

                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2023 Q3 & Q4</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Beta testing and user feedback collection for desktop application</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2023 Q4</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Development of mobile application prototype</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2024 Q1</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                            </div>
                            <h6>NFT marketplace platform with auction functionality</h6>
                        </div>
                        
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2024 Q3</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Release of fully functional desktop application with key features and integrations</h6>
                        </div>

                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2024 Q2 & Q3</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Beta testing and user feedback collection for mobile application</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2024 Q1 & Q2</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>NFT collection with several benefits for early investors</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2024 Q1 & Q2</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                                <div className={LandingStyle.vdotted}></div>
                            </div>
                            <h6>Implementation of user feedback and iteration on desktop application design and functionality</h6>
                        </div>
                        
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2024 Q3</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                                <div className={LandingStyle.vdotted}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Tokens rewards for tasks in our platforms</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2024 Q3</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Tokens cashback for various products</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2024 Q4</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                                <div className={LandingStyle.dotted}></div>
                            </div>
                            <h6>Automated bots with crypto algorithms for traders</h6>
                        </div>
                        <div className={LandingStyle.roadmap_box + " white-glassmorphism"}>
                            <p>2024 Q4</p>
                            <div className="d-flex align-items-center">
                                <div className={LandingStyle.bcircle}></div>
                                {/* <div className={LandingStyle.dotted}></div> */}
                            </div>
                            <h6>More to come. Stay Tuned!</h6>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}