import React from "react";
import { AiFillPlayCircle } from "react-icons/ai";
// import { SiEthereum } from "react-icons/si";
// import { BsInfoCircle } from "react-icons/bs";
import ReactPlayer from 'react-player'
import animationLumen from "../assets/animation_lumen.mp4";
// Reuse common styles
const commonStyles =
  "min-h-[70px] sm:px-0 px-2 sm:min-w-[120px] flex justify-center items-center border-[0.5px] border-gray-400 text-sm font-light text-white";

const Welcome = () => {
  return (
    <div className="flex w-full justify-center items-center">
      <div className="flex mf:flex-row flex-col items-stretch justify-between md:p-20 py-12 px-4">
        <div className="flex flex-2 justify-start items-start flex-col mf:mr-10">
          <h1 style={{paddingTop: "0"}} className="text-3xl sm:text-6xl text-white font-bold text-gradient py-3" data-aos="fade-right" data-aos-delay="100">
            Something big <br /> is coming soon
          </h1>
          <p className="text-left mt-5 text-white font-normal md:w-9/12 w-11/12 text-base" data-aos="fade-right" data-aos-delay="150">
            LumenDex is a modern crypto exchange <br />
            for <strong>everyone</strong>
          </p>
          {/* <button
            type="button"
            className="flex flex-row justify-center items-center my-5 bg-[#2952e3] p-3 rounded-full cursor-pointer hover:bg-[#2546bd]"
            data-aos="fade-right"
            data-aos-delay="300"
          >
            <AiFillPlayCircle className="text-white mr-2" />
            <p className="text-white text-base font-semibold">Get a Demo</p>
          </button> */}

          {/* Keyword grid */}
          <div style={{marginTop:"55px"}} className="grid sm:grid-cols-3 grid-cols-2 w-full" data-aos="fade-up-right" data-aos-delay="600">
            <div className={`rounded-tl-2xl ${commonStyles}`}>
              Reliability
            </div>
            <div className={commonStyles}>Security</div>
            <div className={`sm:rounded-tr-2xl ${commonStyles}`}>
              Decentralized
            </div>
            <div className={`sm:rounded-bl-2xl ${commonStyles}`}>
              Web 3.0
            </div>
            <div className={commonStyles}>User Friendly</div>
            <div className={`rounded-br-2xl ${commonStyles}`}>
              Blockchain
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-1 items-center justify-center w-full mf:mt-0 mt-10">
        <ReactPlayer 
            width="100%"
            height="400px"
            style={{minHeight: '200px', maxHeight: '800px', height: 'auto!important'}}
            url={animationLumen}
            // light={previewImage}
            playing={true}
            loop={true}
            volume={0}
            controls={false}
        />
          {/* Ethereum card */}

          {/* <div className="p-3 flex justify-end items-start flex-col rounded-xl h-40 w-72 my-5 eth-card white-glassmorphism md:hover:scale-125 duration-300" data-aos="fade-left" data-aos-delay="600">
            <div className="flex justify-between flex-col w-full h-full">
              <div className="flex justify-between items-start">
                <div className="w-10 h-10 rounded-full border-2 border-white flex justify-center items-center hover:opacity-75 transition-opacity">
                  <SiEthereum fontSize={21} color="#fff" />
                </div>
                <BsInfoCircle fontSize={17} color="#fff" />
              </div>
              <div>
                <p className="text-white font-light text-sm">
                  Transaction Address
                </p>
                <p className="text-white font-semibold text-lg mt-1">
                  Ethereum
                </p>
              </div>
            </div>
          </div> */}

        </div>
      </div>
    </div>
  );
};

export default Welcome;
