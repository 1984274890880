import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Navbar, Welcome, Footer, Services, Testimonials, Subscribe, SaleCountdown, Roadmap } from "./components";

const App = () => {
  // Set up AOS on component mount
  useEffect(() => {
    Aos.init({
      duration: 1200,
      once: true,
    });
  }, []);

  return (
    // Create page layout
    <div className="min-h-screen">
      <div className="gradient-bg-welcome" style={{paddingBottom:"100px"}}>
        <Navbar />
        <Welcome />
      </div>
      <SaleCountdown />
      <Services />
      <Roadmap />
      {/* <Testimonials /> */}
      {/* <Subscribe /> */}
      <Footer />
    </div>
  );
};

export default App;
